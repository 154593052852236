import { forwardRef, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import Field from '../Field';
import { fieldPropTypes } from '../propTypes';
import { useFormikContext, getIn } from 'formik';
import { initialSyncObj } from 'behavior/captcha';
import { useSimpleTexts } from 'components/sanaText';
import { ValidationTexts } from 'components/objects/forms/validation';

const renderInnerField = () => null;

const CaptchaField = forwardRef(({
  fieldName,
  fieldTitle,
  validation,
  invisible,
  siteKey,
  syncObj,
  ...attributes
}, ref) => {
  const { setFieldValue, setFieldTouched, submitForm, errors } = useFormikContext();

  const localRef = useRef();
  const captchaRef = ref || localRef;
  const reset = useCallback(() => {
    // Captcha reset should be performed with delay to avoid "Cannot read property 'style' of null" error in recaptcha lib.
    setTimeout(() => {
      if (!captchaRef.current)
        return;

      captchaRef.current.reset();
      setFieldTouched(fieldName, false, false);
      setFieldValue(fieldName, '', false);
    }, 250);
  }, [fieldName, setFieldTouched, setFieldValue]);

  useEffect(() => {
    if (syncObj !== initialSyncObj)
      reset();
  }, [syncObj]);

  const [errorMessage] = useSimpleTexts([ValidationTexts.RequiredCaptcha]).texts;
  if (!invisible)
    validation = { ...validation, required: { message: errorMessage } };

  const onChange = value => {
    setFieldValue(fieldName, value);

    if (invisible)
      submitForm();
  };

  return (
    <>
      <Field fieldName={fieldName} fieldTitle={fieldTitle} validation={validation}>
        {renderInnerField}
      </Field>
      <ReCAPTCHA
        ref={captchaRef}
        sitekey={siteKey}
        size={invisible ? 'invisible' : 'normal'}
        badge={invisible ? 'inline' : null}
        onChange={onChange}
        onExpired={reset}
        className="g-recaptcha"
        aria-invalid={!!getIn(errors, fieldName)}
        {...attributes}
      />
    </>
  );
});

CaptchaField.propTypes = {
  ...fieldPropTypes,
  invisible: PropTypes.bool,
  siteKey: PropTypes.string,
  syncObj: PropTypes.object,
};

export default CaptchaField;
